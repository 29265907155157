
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import Dialog from '@/modules/common/components/ui-kit/dialog.vue';
import { FE_ONLY_PROVIDERS } from '@/modules/providers/constants';

import type ProvidersService from '@/modules/providers/providers.service';
import type CompsetsService from '@/modules/compsets/compsets.service';
import type HotelsService from '@/modules/hotels/hotels.service';
import type Item from '@/modules/common/interfaces/item.interface';
import type { HotelNetConfig } from '@/modules/compsets/interfaces';
import type { SettingsHotelService } from '../settings-hotel.service';
import type { HotelNetCalculationFilters } from '../types';

@Component({
    components: {
        CustomSelect,
        Dialog,
        PageWrapper,
    },
})
export default class NetCalculationTab extends Vue {
    @inject(KEY.CompsetsService) private compsetsService!: CompsetsService;
    @inject(KEY.ProvidersService) private providerService!: ProvidersService;
    @inject(KEY.HotelsService) private hotelsService!: HotelsService;
    @inject(KEY.SettingsHotelService) private settingsHotelService!: SettingsHotelService;

    haveSavingError = false;
    isSaving = false;
    statusText = '';

    private _tableData: HotelNetConfig[] | null = null;
    private changedData: Record<number, true> = {};

    get haveChanges() {
        return Object.keys(this.changedData).length > 0;
    }

    get posItems() {
        if (!this.choosenCompset) return [];

        return this.choosenCompset.pos
            .map(pos => ({
                value: pos,
                name: pos,
            }));
    }

    get providerItems() {
        if (!this.choosenCompset) {
            return [];
        }

        const providers = this.choosenCompset.rateProviders
            .map(p => this.providerService.allProviders[p].groupDocName);
        const filteredProviders = Array.from(new Set(providers.filter(p => !FE_ONLY_PROVIDERS.includes(p))));
        const providersMap = this.providerService.getGroupDocNameToProviderMap(this.choosenCompset.rateProviders);

        return this.providerService
            .toItemsList(filteredProviders, true)
            .map(providerItem => ({
                ...providerItem,
                name: this.providerService.allProviders[providersMap[providerItem.value]]?.label || providerItem.name,
            }));
    }

    get compsetItems(): Item[] {
        const { compsets } = this.compsetsService;

        if (!compsets) {
            return [];
        }

        return compsets
            .map(compset => ({
                value: compset.id,
                name: `${compset.name} (${this.$t(`compset.${compset.type}`)})`,
            }));
    }

    get hotelId() {
        return +this.$route.params.hotelId;
    }

    get choosenCompset() {
        const { compset: compsetId } = this.settingsHotelService.hotelNetCalculationFilters as HotelNetCalculationFilters;
        const compset = this.compsetsService.getCompset(compsetId);

        return compset;
    }

    get tableData() {
        this._tableData = this.originalTableData;
        return this._tableData;
    }

    private get originalTableData() {
        const { pos, provider, compset } = this.settingsHotelService.hotelNetCalculationFilters;
        const { hotelNetCalculations } = this.settingsHotelService;

        if (!pos
            || !provider
            || !compset
            || !hotelNetCalculations
        ) {
            return null;
        }

        const hotelIds = [this.hotelId, ...(this.choosenCompset?.competitors || [])];

        if (!hotelNetCalculations[compset]
            || !hotelNetCalculations[compset][provider]
            || !hotelNetCalculations[compset][provider][pos]
        ) {
            return hotelIds.map(hotelId => ({
                hotelName: this.hotelsService.hotelNames[hotelId],
                id: hotelId,
                vat: 0,
                extraFees: 0,
            }));
        }

        const dataByFilters = hotelNetCalculations[compset][provider][pos];

        return hotelIds.map(hotelId => {
            const hotelName = this.hotelsService.hotelNames[hotelId];

            return {
                hotelName,
                id: hotelId,
                vat: dataByFilters[hotelId]?.vat || 0,
                extraFees: dataByFilters[hotelId]?.extraFees || 0,
            } as HotelNetConfig;
        });
    }

    async handleSave() {
        const { pos, compset, provider } = this.settingsHotelService.hotelNetCalculationFilters;

        if (!provider
            || !pos
            || !compset
            || !this._tableData
        ) {
            return;
        }

        const tableData = this._tableData.filter(node => this.changedData[node.id]);

        this.isSaving = true;
        this.statusText = '';

        try {
            const hotelId = +this.$route.params.hotelId!;
            await this.compsetsService.saveNetCalculationSettings(hotelId, compset, provider, pos, tableData);
            this.settingsHotelService.hotelNetCalculationLoading.reset();

            this.clearChangesData();
            this.statusText = this.$tc('saved');
        } catch {
            this.statusText = this.$tc('somethingWrong');
        } finally {
            this.isSaving = false;
        }
    }

    setCompset(compsetId: string) {
        if (this.settingsHotelService.hotelNetCalculationFilters.compset === compsetId) {
            return;
        }

        this.settingsHotelService.hotelNetCalculationFilters = { compset: compsetId };
        this.clearChangesData();
    }

    setProvider(p: string | null) {
        if (this.settingsHotelService.hotelNetCalculationFilters.provider === p) {
            return;
        }

        this.settingsHotelService.hotelNetCalculationFilters = { provider: p };
    }

    setPos(p: string) {
        if (this.settingsHotelService.hotelNetCalculationFilters.pos === p) {
            return;
        }
        this.settingsHotelService.hotelNetCalculationFilters = { pos: p };
    }

    updateChangeState(hotelId: number) {
        this.changedData = {
            ...this.changedData,
            [hotelId]: true,
        };
    }

    checkValue(index: number) {
        if (!this._tableData) {
            return;
        }

        const item = this._tableData[index];

        item.extraFees = +item.extraFees || 0;
        item.vat = +item.vat || 0;
    }

    private clearChangesData() {
        this.changedData = {};
    }
}
